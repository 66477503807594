<template>
  <div>
    <div :class="{dragging: isDragging, clicking: isPressed, [`slides-${items}`]:true}" @mousedown="startDragging" class="bgl-slider" ref="bglSlider">
      <div v-if="isDragging" class="blocker"></div>
      <slot/>
    </div>
    <div class="Handlers">
      <span @click="next">
        <slot name="next"></slot>
      </span>
      <span @click="prev">
        <slot name="prev"></slot>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from 'vue'
const bglSlider = ref(null)

const props = defineProps({
  freeDrag: {
    type: Boolean,
    default: true
  },
  items: {
    type: Number,
    default: 4
  },
  index: {
    type: Number,
    default: 0
  }
})

onMounted(()=>{
  bglSlider.value.querySelectorAll('img').forEach(e=>e.setAttribute("draggable",false))
  for (const e of bglSlider.value.children){
    e.setAttribute("draggable",false)
    e.addEventListener("click", (e)=>e.preventDefault())
  }
})

const goToSlide = (index)=>{
  if (index < 0 || index > bglSlider.value.children.length - 1) return;
  bglSlider.value.scrollLeft = bglSlider.value.children[0].clientWidth * index;
  activeIndex.value = index;
  emitChange()
}



watch(()=>props.index, (val)=>{
  goToSlide(val)
})

const activeIndex = ref(0)
const isDragging = ref(false)
const startX = ref(0)
const scrollLeft = ref(0)
const isPressed = ref(false)

const stopDragging = () => {
  isPressed.value = false;
  startX.value = 0;
  document.removeEventListener('mousemove', move);
  document.removeEventListener('mouseup', stopDragging);
  document.removeEventListener('dragend', stopDragging)
  setTimeout(()=>isDragging.value = false, 100);
  emitChange()
}

// const emit = defineEmits(['change'])

function emitChange(){
// //  find the first visible slide
//   for (const index in bglSlider.value.children){
//     const slide = bglSlider.value.children[index]
//     console.log(slide.offsetLeft, bglSlider.value.scrollLeft)
//     if (slide.offsetLeft == bglSlider.value.scrollLeft){
//       emit('change', {item:{index: parseInt(index), slide}})
//       break;
//     }
//   }
  
}


// eslint-disable-next-line no-unused-vars
const startDragging = (e) => {
      if (e.button !== 0 || !props.freeDrag) return;
      startX.value = e.pageX - bglSlider.value.offsetLeft;
      scrollLeft.value = bglSlider.value.scrollLeft;
      isPressed.value = true;
      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', stopDragging);
      document.addEventListener('dragend', stopDragging)
    }

const move = (e)=>{
      const x = e.pageX - bglSlider.value.offsetLeft;
      const walk = x - startX.value;
      if (walk > 20 || walk < -20) isDragging.value = true;
      if (!isDragging.value) return;
      const scroll = x - startX.value;
      bglSlider.value.scrollLeft = scrollLeft.value - scroll;
    }
// eslint-disable-next-line no-unused-vars
const next=()=>{
      const slide = bglSlider.value.children[0];
      const slideWidth = slide.clientWidth;
      if (bglSlider.value.scrollLeft >= bglSlider.value.scrollWidth - bglSlider.value.clientWidth)
        bglSlider.value.scrollLeft = 0;
      else bglSlider.value.scrollLeft += slideWidth;
      emitChange()
    }
// eslint-disable-next-line no-unused-vars
const prev = ()=>{
      const slide = bglSlider.value.children[0];
      const slideWidth = slide.clientWidth;
      if (bglSlider.value.scrollLeft > 0)
      bglSlider.value.scrollLeft -= slideWidth;
      else bglSlider.value.scrollLeft = bglSlider.value.scrollWidth;
      emitChange()
}

</script>

<style scoped>
.blocker{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
  .bgl-slider {
    display: grid;
    position:relative;
    scroll-behavior: smooth;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
  }
  .bgl-slider.slides-4 {
    grid-auto-columns: 24%;
    gap: 1%;
  }
  .bgl-slider.slides-3 {
    grid-auto-columns: 33%;
    gap: 1%;
  }

  .bgl-slider.slides-2 {
    grid-auto-columns: 50%;
    gap: 1%;
  }

  .bgl-slider.slides-1 {
    grid-auto-columns: 100%;
    gap: 1%;
  }

  .bgl-slider::-webkit-scrollbar{
    display: none;
  }

  .bgl-slider * {
    scroll-snap-align: start;
  }
  .dragging.bgl-slider {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    scroll-snap-type: unset;
  }

  .clicking.bgl-slider {
    scroll-behavior: unset;
  }

  .dragging.bgl-slider * {
    scroll-snap-align: unset;
    user-select: none;
  }

  @media screen and (max-width: 1280px) {
    .bgl-slider.slides-4 {
      grid-auto-columns: 33%;
    }
  }

  @media screen and (max-width: 991px) {
    .bgl-slider.slides-4 {
      grid-auto-columns: 50%;
    }
  }

  @media screen and (max-width: 600px) {
    .bgl-slider.slides-4 {
      grid-auto-columns: 100%;
    }
  }
</style>