<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { settings } from '@/data/localData.json'

export default {
  name: "App",
  metaInfo() {
    return {
      ...this.seo({
        title: settings.title,
        image: settings.og.imageURL,
        description: settings.metaDescription
      }),
      titleTemplate: settings.titleTemplate,
    };
  },
}
</script>

<style src="./main.css"></style>