<template>
    <div class="nav">
        <img
            width="20"
            height="16"
            src="../assets/menu.svg"
            class="menu-icon"
            alt=""
            @click="opennavbar = true"
        >
        <router-link
            to="/"
            class="logo"
        >
            <img
                width="280"
                height="48"
                src="../assets/Logo.svg"
                alt="Clarity"
            ></router-link>
        <!-- <div class="blog-logo-wrap">
            <router-link
                to="/blog"
                class="blog-logo"
            >
                {{ $t('Blog') }}
            </router-link>
        </div> -->
        <div
            class="nav-menu-wrap"
            :class="{ opennav: opennavbar }"
        >
            <div class="close-menu"><img
                    alt=""
                    src="../assets/close-white.svg"
                    class=""
                    width="18"
                    height="18"
                    @click="opennavbar = false"
                ></div>
            <router-link
                to="/who-we-are"
                class="nav-link"
            >{{ $t('About Us') }}
            </router-link>
            <router-link
                to="/why-sell-with-us"
                class="nav-link"
            >{{ $t('Why') }}
            </router-link>
            <router-link
                to="/how-it-works"
                class="nav-link"
            > {{ $t('How') }}
            </router-link>
            <router-link
                to="/blog"
                class="nav-link"
            >
                {{ $t('Blog') }}
            </router-link>
            <router-link
                to="/contact-us"
                class="nav-link"
            >
                {{ $t('Contact') }}</router-link>
            <div class="lang-wrap">
                <button
                    class="lang"
                    @click="$setLang('en')"
                    :class="{ selected: $lang === 'en' }"
                >
                    EN
                </button>
                <button
                    class="lang"
                    @click="$setLang('fr')"
                    :class="{ selected: $lang === 'fr' }"
                >
                    FR
                </button>
            </div>
        </div>
        <div
            class="meu-bg"
            :class="{ opennav: opennavbar }"
            @click="opennavbar = false"
        ></div>
        <router-link
            to="/form"
            class="top-btn"
        >
            <p>
                {{ $t('Get the best offer') }}
            </p>
            <img
                alt=""
                src="../assets/small-arrow.svg"
            >
        </router-link>
    </div>
</template>

<script>
export default {
    data: () => ({
        opennavbar: false,
    }),
    name: "topnav",
};
</script>
