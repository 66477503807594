import Home from '@/views/Home.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  { path: '/who-we-are', name: 'WhoWeAre', component: () => import('@/views/Whoweare.vue') },
  { path: '/thank-you', name: 'ThankYou', component: () => import('@/views/ThankYou.vue') },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: () => import('@/views/PrivacyPolicy.vue') },
  { path: '/terms-conditions', name: 'Terms', component: () => import('@/views/Terms.vue') },
  { path: '/cookies-policy', name: 'Cookies', component: () => import('@/views/Cookies.vue') },
  { path: '/why-sell-with-us', name: 'WhySellWithUs', component: () => import('@/views/Whysellwithus.vue') },
  { path: '/how-it-works', name: 'HowItWorks', component: () => import('@/views/HowItWorks.vue') },
  { path: '/blog', name: 'Blog', component: () => import('@/views/Blog.vue') },
  { path: '/contact-us', name: 'ContactUs', component: () => import('@/views/Contactus.vue') },
  { path: '/form', name: 'Form', component: () => import('@/views/Form.vue') },
  { path: '/blog/:id', name: 'Blogpage', component: () => import('@/views/Blogpage.vue') },
  { path: '/product/:id', name: 'ProductPage', component: () => import('@/views/ProductPage.vue') },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash)
      return {
        behavior: 'smooth',
        selector: to.hash,
        offset: { x: 0, y: 0 },
      };
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  let query = { ...from.query, ...to.query };
  if (!to.query.option) delete query.option;
  if (JSON.stringify(to.query) === JSON.stringify(query)) return next();
  else next({ ...to, query });
});

export default router;
