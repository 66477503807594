<template>
    <div class="footer"><a href="/" class="footer-logo">
            <img width="320" height="55" src="../assets/logo-footer.svg" alt="Clarity"></a>
        <div class="footer-flex">
            <div>
                <router-link to="/who-we-are" class="footer-link">
                    {{ $t('Who we are') }}
                </router-link>
                <router-link to="/why-sell-with-us" class="footer-link">
                    {{ $t('Why Sell with us') }}
                </router-link>
                <router-link to="/how-it-works" class="footer-link">
                    {{ $t('How it works') }}
                </router-link>
                <router-link to="/blog" class="footer-link">
                    {{ $t('Blog') }}
                </router-link>
                <router-link to="/contact-us" class="footer-link">
                    {{ $t('Contact') }}
                </router-link>
            </div>
            <div class="footer-more-links"><router-link to="/privacy-policy" class="footer-link">
                    {{ $t('PRIVACY POLICY') }}
                </router-link>
                <router-link to="/terms-conditions" class="footer-link">
                    {{ $t('Terms & Conditions') }}
                </router-link>
                <router-link to="/cookies-policy" class="footer-link">
                    {{ $t('Cookies Policy') }}
                </router-link>
            </div>
        </div>
        <div class="footer-flex for-icons">
            <div class="footer-icons-links-wrap"><a :href="contactPage.facebook" target="_blank" class="footer-soc-link">
                    <img width="38" height="38" src="../assets/Facebook.svg" alt="Facebook"></a>
                <a :href="contactPage.instagram" target="_blank" class="footer-soc-link">
                    <img width="39" height="39" src="../assets/Instagram.svg" alt="Instagram"></a><a
                    :href="contactPage.whatsapp" target="_blank" class="footer-soc-link">
                    <img width="39" height="38" src="../assets/Whatsapp.svg" alt="WhatsApp"></a>
            </div>
            <div class="footer-icons-links-wrap">
                <div>
                    <img height="48" width="121" src="../assets/igi.svg" class="partner-logo" alt="IGI">
                </div>
                <div class="ver-footer-line"></div>
                <div>
                    <img height="48" width="143" src="../assets/Gemological_Institute_of_America_logo.svg"
                        class="partner-logo" alt="GIA">
                </div>
                <div class="ver-footer-line"></div>
                <div>
                    <img height="48" width="70" src="../assets/Malca-Amit-Logo.svg" class="partner-logo" alt="Malca-Amit">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { contactPage } from '@/data/localData.json';
export default {
    name: "botfooter",
    computed:{
        contactPage(){
            return contactPage
        }
    }
}
</script>