import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import {translation} from './data/localData.json';
import vueMeta from 'vue-meta';
import seoMixin from './mixins/seoMixin';

Vue.use(vueMeta, {ssrAppId: "clarity"});
Vue.use(VueRouter);
Vue.mixin(seoMixin);

let lang = localStorage.getItem('lang') || 'fr';
document.documentElement.lang = lang;
Vue.prototype.$lang = lang;
Vue.prototype.$setLang = (lang) => {
  Vue.prototype.$lang = lang;
  localStorage.setItem('lang', lang);
  window.location.reload();
};

Vue.prototype.$gtag = (eventName) => {
  if (!window) return
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push({'event': eventName});
}

Vue.prototype.$t = (str) => {
  let item = translation.find((t) => t.source.toLowerCase() === str.toLowerCase() && t.lang.value === lang);
  if (item) return item.translation || str;
  return str;
};

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
