
export default {
  methods: {
    seo({ title, twitterHandle, image, description, imageCaption, ssrAppId = "clarity" }){
      const seo = {}
      const headers = []
      seo.ssrAppId = ssrAppId;
      if (imageCaption) {
        headers.push({
          hid: "twitter:image:alt",
          name: "twitter:image:alt",
          content: imageCaption ? imageCaption.replace(/<[^>]*>/g, "") : "",
          vmid:"twitter:image:alt"
        });
        headers.push({
          hid: "og:image:alt",
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: imageCaption ? imageCaption.replace(/<[^>]*>/g, "") : "",
        });
      }
      if (title) {
        seo.title = title;
        headers.push({
          hid: "twitter:title",
          vmid: "twitter:title",
          name: "twitter:title",
          content: title ? title.replace(/<[^>]*>/g, "") : "",
        });
        headers.push({
          hid: "og:title",
          vmid: "og:title",
          property: "og:title",
          content: title ? title.replace(/<[^>]*>/g, "") : "",
        });
      }
      if (twitterHandle)
      headers.push({ name: "twitter:site", content: twitterHandle, vmid: "twitter:site", });
    if (image) {
      headers.push({ vmid: "twitter:card", name: "twitter:card", content: "summary_large_image" });
      headers.push({
        hid: "twitter:image",
        vmid: "twitter:image",
        name: "twitter:image",
        content: image,
      });
      headers.push({
        hid: "og:image:secure_url",
        vmid: "og:image:secure_url",
        property: "og:image:secure_url",
        content: image,
      });
      headers.push({ vmid: "og:image", hid: "og:image", property: "og:image", content: image });
    }
    if (description) {
      headers.push({
        hid: "description",
        vmid: "description",
        name: "description",
        content: description ? description.replace(/<[^>]*>/g, "") : "",
      });
      headers.push({
        hid: "twitter:description",
        vmid: "twitter:description",
        name: "twitter:description",
        content: description ? description.replace(/<[^>]*>/g, "") : "",
      });
      headers.push({
        hid: "og:description",
        vmid: "og:description",
        property: "og:description",
        content: description ? description.replace(/<[^>]*>/g, "") : "",
      });
    }
      seo.meta = headers;
      return seo;
    }
  }
};