<template >
  <div>
    <topnav></topnav>
    <div class="page-wrap">
      <div class="home-hero">
        <p class="txt110 serif txt-white">
          {{ $t('Your diamond’s true worth') }}
        </p>
        <h1 class="subtitle"> {{ $t('CLARITY helps you get the best offer for diamonds and jewelry quickly and easily')
        }}.
        </h1>
        <button
          @click="() => {
              $gtag('CTA_hero_click');
              $router.push('/form')
            }"
          class="home-hero-btn"
        >
          <span>
            {{ $t('Start Here') }}
          </span>
          <img
            alt=""
            src="../assets/small-arrow-dark.svg"
          >
        </button>
        <img
          height="590"
          width="1410"
          alt="Clarity Jewellery"
          src="../assets/hero-m.webp"
          class="home-hero-img mobile"
        />
        <img
          height="590"
          width="1410"
          alt="Clarity Jewellery"
          src="../assets/hero.webp"
          class="home-hero-img desktop"
        />
      </div>
      <div class="top-banner">
        <div class="top-banner-item">
          <img
            width="53"
            height="53"
            src="../assets/top-icon-1.svg"
            alt=""
          >
          <p>{{ $t('Free evaluation by expert gemologist') }}</p>
        </div>
        <div class="top-banner-item">
          <img
            width="60"
            height="35"
            alt=""
            src="../assets/top-icon-2.svg"
          >
          <p>{{ $t('Reach over 2000 buyers') }}</p>
        </div>
        <div class="top-banner-item">
          <img
            width="44"
            height="44"
            src="../assets/top-icon-3.svg"
            alt=""
          >
          <p>{{ $t('Worldwide reach from your home') }}</p>
        </div>
        <div class="top-banner-item">
          <img
            width="39"
            height="39"
            src="../assets/top-icon-4.svg"
            alt=""
          >
          <p>{{ $t('Get the best offer in 24 hours') }}</p>
        </div>
      </div>
      <BglCarousel>
        <router-link
          v-for="slide  in  $local.carousel.filter(s => !s.draft) "
          :key="slide._id"
          :to="`/product/${slide._id}`"
          class="carousel-box"
        >
          <div class="carousel-box-top">
            <p class="carousel-box-sold">{{ $t('Sold') }}</p>
            <img
              height="355"
              width="330"
              :src="slide.imageGallery[0].imageURL"
              :alt="slide.sliderText || ''"
              loading="lazy"
            >
          </div>
          <div class="carousel-box-bot">
            <p class="carousel-box-price"><span>€</span>{{ slide.price.toLocaleString() }}</p>
            <p v-if="$lang === 'en'">{{ slide.sliderText }}</p>
            <p v-else-if="$lang === 'fr'">{{ slide.sliderTextFrench || slide.sliderText }}</p>
          </div>
        </router-link>
        <template #next>
          <img
            width="50"
            height="40"
            alt=""
            src="../assets/car-arrow-1.svg"
            class="car-arrow"
          />
        </template>
        <template #prev>
          <img
            width="50"
            height="40"
            alt=""
            src="../assets/car-arrow-2.svg"
            class="car-arrow"
          />
        </template>
      </BglCarousel>

      <div class="slogen-sec">
        <img
          width="935"
          height="135"
          alt="Logo"
          class="slogen-en"
          src="../assets/slogen.svg"
        />
        <img
          width="1019"
          height="135"
          alt="Logo"
          class="slogen-fr"
          src="../assets/slogen-fr.svg"
        />

      </div>
      <div class="how-it-works-sec">
        <h2 class="txt88 serif">
          {{ $t('How it works') }}
        </h2>
        <div class="how-it-works-wrap">
          <div class="how-it-works-item">
            <img
              width="76"
              height="76"
              src="../assets/icon-oval-1.svg"
              alt=""
            >
            <p class="serif">{{ $t('Fill a simple online form') }}</p>
          </div>
          <div class="how-it-works-item">
            <img
              width="76"
              height="76"
              src="../assets/icon-oval-2.svg"
              alt=""
            >
            <p class="serif">{{ $t('Get an evaluation') }}</p>
          </div>
          <div class="how-it-works-item">
            <img
              width="76"
              height="76"
              src="../assets/icon-oval-3.svg"
              alt=""
            >
            <p class="serif">{{ $t('Secure Shipment') }}</p>
          </div>
          <div class="how-it-works-item">
            <img
              width="76"
              height="76"
              src="../assets/icon-oval-4.svg"
              alt=""
            >
            <p class="serif">{{ $t('Get the best offer') }}</p>
          </div>
          <div class="how-it-works-item">
            <img
              width="76"
              height="76"
              src="../assets/icon-oval-5.svg"
              alt=""
            >
            <p class="serif">{{ $t('Get paid in 24 hours') }}</p>
          </div>
        </div>
        <router-link
          class="learn-more serif"
          to="/how-it-works"
        >{{ $t('Learn More') }}</router-link>
      </div>
      <div class="saying-sec">
        <p class="saying-head txt-black serif">
          {{ $t('what people are saying') }}
        </p>
        <BglCarousel
          class="testimonials"
          :freeDrag="false"
          :items="1"
        >
          <template #next>
            <img
              alt=""
              src="../assets/black-arrow.svg"
              class=" arrow-owl saying-arrow"
            />
          </template>
          <div
            v-for=" testimonial  in  $local.testimonials"
            :key="testimonial._id"
          >
            <div class="testimonial-name-wrap">
              <p class="testimonial-name txt-black">{{ testimonial.name }}</p>
              <div class="stars-wrap"><img
                  height="32"
                  width="35"
                  alt="Rating"
                  v-for=" star  in  testimonial.rating "
                  :key="star"
                  src="../assets/Star.svg"
                  class="star"
                /></div>
            </div>
            <p class="testimonial-date">{{ new Date(testimonial.date).toLocaleString('en-US', {
              day: '2-digit', month:
                'short', year: '2-digit'
            }) }}</p>
            <div
              class="testimonial-txt txt-black"
              v-html="$t(testimonial.body)"
            ></div>
          </div>
        </BglCarousel>

      </div>
      <div class="free-evaluation-sec">
        <h3 class="txt88 serif">
          {{ $t('Get a a free evaluation') }}
        </h3>
        <p class="evaluation-sub"> {{ $t('What type of jewelry do you have') }}?</p>
        <div class="evaluation-wrap">
          <div
            v-for="item, option in $local.formConfiguration.options"
            :key="item._id"
            class="evaluation-item big"
            @click="() => {
              $gtag('product_click');
              $router.push({
                path: '/form', query: { option }
              })
            }"
          >
            <img
              height="100"
              width="100"
              loading="lazy"
              :src="item.icon.imageURL"
              :alt="item.optionName"
            >
            <h3 v-if="$lang === 'en'">{{ item.optionName }}</h3>
            <h3 v-if="$lang === 'fr'">{{ item.optionNameFrench || item.optionName }}</h3>

          </div>
        </div>
        <img
          class="evaluation-bg"
          src="../assets/Texture.svg"
          alt=""
        >
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import { testimonials, formConfiguration, carousel as c } from '@/data/localData.json';
import BglCarousel from "@/components/bglCarousel.vue";
export default {
  components: {
    Topnav,
    Botfooter,
    BglCarousel
  },
  data() {
    return {
      loaded: false,
    }
  },
  mounted() {
    document.addEventListener("scroll", this.onScrollEvent);
  },
  computed: {
    $local() {
      return {
        testimonials, formConfiguration, carousel: c
      }
    }
  },
  methods: {
    onScrollEvent() {
      this.loaded = true
      document.removeEventListener("scroll", this.onScrollEvent);
    }
  },
  name: "Home",
};
</script>


<style>
.hide-me {
  display: none;
}
</style>